@import "../../shared/styles/Variables.scss";

table {
  border: 1px solid forestgreen;
  width: 100%;

  th {
    border-bottom: 1px solid black;
    background-color: rgb(229, 229, 229);
    padding-block: 10px;
  }

  td {
    text-align: center;
  }
}

table,
th,
td {
  border: 1px solid rgb(216, 216, 216);
  border-collapse: collapse;
  padding-block: 5px;
}

.btn-check {
  padding: 0;
  background: none;
  border-color: transparent;
  position: absolute;
  top: 4px;
  right: 4px;
}

.custome-tab {
  margin-top: 10px;
  .tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
  }

  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 8px 16px;
    transition: 0.3s;
    font-size: 17px;
    &:hover {
      background-color: #ddd;
    }
    &.active {
      background-color: #ccc;
    }
  }

  .tabcontent {
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
  }
}

.pagination-main {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  ul {
    display: flex !important;
    padding-left: 0 !important;
    list-style: none !important;
    li {
      text-align: center;
      display: flex !important;
      border: 1px solid lightgray;
      min-width: 25px;
      min-height: 25px;
      &.disabled {
        background-color: lightgray;
      }
      &:hover:not(.selected) {
        background-color: #ddd;
      }
      &.selected {
        background-color: $color-btn;
        color: white;
        border: 1px solid $color-btn;
      }
      a {
        padding: 0px;
        padding-inline: 8px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.grid {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 12px;
  margin-block: 6px;
  .grid-item {
    min-height: 250px;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    padding: 10px;
    font-size: 14px;

    &.hide {
      background-color: transparent;
    }
  }
}

.modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  // padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 320px;
    p {
      font-weight: bold;
    }
  }
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    &:hover,
    &:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .grid {
    .grid-item {
      min-height: 170px;
    }
  }
}
