
// $color-app-bg: #fafcff;
$color-app-bg: rgb(248,250,254);
// $color-app-bg: #f0f8ff;

$color-app: #ff656a;

$color-btn: #ff656a;
$color-btn-hover: #ff656a99;
$color-btn-hover-border: #ff656a20;
$color-btn-disable: #ff656a80;

$color-title: rgb(59, 71, 84);
$color-desc: rgb(121, 143, 163);

$width-main: 90%;
$width-main-mobile: 96%;