.main-Contact {
    display: flex;
    .sub {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .main-Contact {
        display: grid;
        .sub {
            width: 100%;
        }
    }   
}