.row {
  content: "";
  display: flex;
  clear: both;
  gap: 20px;
  margin-top: 20px;
  .column {
    float: left;
    width: 50%;
    padding: 20px;
    background-color: rgb(242, 242, 240);
    .content {
        background-color: white;
        padding: 10px;
        border-radius: 10px;
        height: 90%;
        .title {
            font-size: 22px;
            font-weight: 700;
            margin: 0px;
            text-align: center;
        }
    }
  }
}
