.loader-main {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    padding-left: 30px;
    border-radius: 8px;
    // border: 1px solid #888;
    // width: 320px;
  }
}
