.carousel {
    display: none;
}

.carousel.carousel-slider {
    display: block;
}

.banner-view {
    height: 350px;
    width: 100%;
}

.banner-item {
    height: 350px;
    background-color: white;
    display: flex;
    .text {
        height: 100%;
        flex: 1;
        background-color: #FF656A; //#800000; //#FF656A //#1458CD;
        align-content: center;
        display: grid;
        font-size: 30px; //34px //32px;
        font-weight: 500;
        padding: 40px;
        color: white;
        border-bottom-right-radius: 200px;
        border-top-right-radius: 200px;
        text-align: center;
    }
    .image {
        height: 100%;
        pointer-events: none;
        // object-fit: fill;
        object-fit: cover;
        flex: 1;
    }
}

@media (max-width: 768px) {
    .banner-view {
        height: 200px;
    }
    
    .banner-item {
        height: 200px;
        .text {
            font-size: 18px;
            padding: 30px;
        }
    }
}