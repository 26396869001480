.app-input-main {
    display: grid;
    gap: 2px;
    min-width: 250px;
    label {
        color: gray;
        font-size: 14px;
    }
}

.app-input {
    padding: 6px;
}

.app-select {
    padding: 6px;
}