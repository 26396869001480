@import "./shared//styles/Variables.scss";

.pt-10 {
  padding-top: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-0 {
  margin-top: 0px;
}

.app {
  min-height: 100vh;
  display: grid;
  align-content: space-between;
  background-color: $color-app-bg;
  .main {
    width: $width-main;
    margin-inline: auto;
    padding-block: 20px;
  }
  .footer {
    width: 100%;
    margin-inline: auto;
    margin-top: 50px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.app-title {
  color: $color-title;
  font-weight: 800;
}

.app-desc {
  color: $color-desc;
  // font-weight: 800;
}

.btn-link {
  background: none;
  border: none;
  text-align: left;
  color: rgb(61, 108, 229);
  text-decoration: underline;
  padding: 0px;
  cursor: pointer;
}

.app-button {
  padding-block: 6px;
  padding-inline: 14px;
  border-radius: 6px;
  font-weight: bold;
  border: none;
  background-color: $color-btn;
  color: white;
  cursor: pointer;
  &:disabled {
    background-color: $color-btn-disable;
  }
  &:hover {
    background-color: $color-btn-hover;
  }
  &.border {
    background-color: transparent;
    border: 1px solid $color-btn;
    color: $color-btn;
    &:hover {
      background-color: $color-btn-hover-border;
    }
  }
}

.border-shadow {
  background-color: white !important;
  box-shadow: 1px 1px 10px 1px lightgray;
  border-radius: 6px;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 768px) {
  .app {
    .main {
      width: $width-main-mobile;
    }
  }
}
