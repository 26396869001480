@import "../../shared/styles/Variables.scss";

.header-main {
  display: flex;
  align-items: center;
  justify-content: space-around;
  // background-color: $color-app;
  background-color: white;
  height: 80px;
  // height: 100px; // for logo
  box-shadow: 1px 1px 10px 1px lightgray;

  .sub {
    width: $width-main;
    justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 15px;
    .logo {
      // height: 100px, // Form logo
      //   width: 230px // Form logo
      // also change in @media (max-width: 768px)
      height: 30px;
      object-fit: contain;
    }
    .view-btn {
      gap: 8px;
      display: flex;
      .btn-header {
        background: none;
        border: none;
        cursor: pointer;
        padding: 10px;
        font-size: 15px;
        &.selected {
          font-weight: bold;
        }
      }
    }
  }
}

.footer-main {
  padding-top: 40px;
  padding-bottom: 30px;
  display: flex;
  width: $width-main;
  .footer-main-detail {
    display: flex;
    gap: 30px;
    .useful-links {
      flex: 1;
      margin-top: 10px;
      display: grid;
      gap: 8px;
      align-content: baseline;
    }
  }
}

.footer-copyright {
  width: 100%;
  height: 80px;
  // background-color: $color-app;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid lightgrey;
  span {
    width: $width-main;
  }
}

@media (max-width: 768px) {
  .header-main {
    justify-content: normal;
    padding-inline: 10px;
    .sub {
      width: $width-main-mobile;
      .logo {
        height: 20px;
        // height: 100px, // Form logo
      }
    }
  }

  .footer-main {
    width: $width-main-mobile;
    flex-direction: column-reverse;
    gap: 30px;
    padding-top: 20px;
    .footer-main-detail {
      .useful-links {
        flex: 0.7;
      }
    }
  }
}
